import React from 'react';
import styled from "styled-components";
import { Modal, Box, Typography, Button } from '@mui/material';

const TermsOfServiceModal = ({ open, onClose, onAccept }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="terms-of-service-modal-title"
      aria-describedby="terms-of-service-modal-description"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Box
          width={800}
          bgcolor="background.paper"
          boxShadow={24}
          p={4}
          borderRadius={2}
          overflow="auto"
          maxHeight="80vh"
        >
          <Typography id="terms-of-service-modal-title" variant="h6" component="h2">
            <strong>Market Research Agreement</strong>
          </Typography>
          <TextContainer id="terms-of-service-modal-description">
            <p>This Market Research Agreement ("Agreement") is entered into as of the date you click “agree” or otherwise indicate your assent (the "Effective Date") by and between gap intelligence LLC ("Company") and you ("Dealer").  Company and Dealer are each sometimes individually referred to herein as a "Party" and both entities are sometimes collectively referred to herein as the "Parties."  By clicking "agree" or otherwise indicating your assent, you agree to be bound by this Agreement.  If you are entering into this Agreement on behalf of an entity, you represent that you have the authority to and you hereby bind such entity to this Agreement.   This is a legally enforceable contract.</p>
            <p>Company and Dealer, intending to be legally bound, agree as follows:</p>

            <p><strong>1. TERM AND TERMINATION</strong></p>

            <p>1.1 The initial term of this Agreement will be for one (1) year from the Effective Date (the "Initial Term"). Thereafter, the Agreement will automatically renew for successive one (1) year periods (each a "Renewal Term" and together with the Initial Term, the "Term") unless and until either Company or Dealer gives the other Party written notice of non-renewal at least thirty (30) days before the end of the Initial Term or the then-current Renewal Term.</p>

            <p>1.2 Either Party may terminate this Agreement at any time, with or without cause, upon thirty (30) days' written notice to the other Party.</p>

            <p><strong>2. SCOPE OF THE AGREEMENT</strong></p>

            <p>2.1 Dealer agrees to provide Dealer wholesale cost pricelists updates as requested by the Company.  Collection cycles will take place 2x yearly (“H1” first half of year and “H2” second half of year) for all countries, excluding Canada, Mexico, and Brazil (“H2” second half of year only).</p>
            
            <p>Wholesale cost pricelists must be official manufacturer lists as provided to dealers/authorized partners.</p>

            <p>(a) Pricelists should include the entire portfolio of A3, A4, and production (where applicable) contractual or dealer channel devices.</p>

            <p>(b) Pricelists should include up to date hardware, accessories, and supplies/consumables pricing as available to the dealer per manufacturer.</p>

            <p>(c) Pricelists must be updated in some way since previous collection cycle (i.e., includes new products, updated prices, official date change on file, etc.) in order to be considered current. The Company will not compensate for identical pricelist from previous collection cycle.</p>

            <p>(d) Requested manufacturer pricelists may vary as needed by the Company. Dealer will be notified in advance of the upcoming collection cycle which manufacturer pricelists are needed.</p>

            <p>(e) Dealer may also send optional additional information such as dealer program structure/agreement information or promotions documents.  Compensation for such documents will vary based on value and extent of data provided.</p>

            <p>2.3 Company shall have the unrestricted right to use, modify, reproduce, distribute, display, and disclose the data for any purpose, including providing market research services to other parties.</p>

            <p>2.4 Company reserves the right to reach out to other contacts for pricelist if Dealer is unable to provide pricelist at least 1 month in advance of report publishing date.</p>

            <p><strong>3. REPRESENTATIONS, WARRANTIES, AND COVENANTS REGARDING DATA</strong></p>

            <p>3.1 Dealer represents, warrants, and covenants to Company that:</p>

            <p>(a) The data provided is current, accurate, true, lawful, lawfully obtained, and complete, and shall not infringe or violate any third-party rights. Dealer shall update the data as soon as practicable where applicable.</p>

            <p>(b) All necessary notices have been provided by Dealer, and all required rights, consents, and permissions have been obtained to lawfully provide the data to Company. Company is permitted to use such data under this Agreement. Dealer confirms that it has the right to obtain or access any data, materials, or other content requested or ordered from Company in connection with the services provided under this Agreement.</p>

            <p>(c) The data does not contain any information that is protected health information or subject to HIPAA compliance or other relevant law or regulation; any information subject to SOX, GLBA requirements or other relevant law or regulation; or any information that falls within the definition of “special categories of data” under data protection laws.</p>

            <p>(d) Dealer is in compliance with all laws applicable to its performance under this Agreement.</p>

            <p>(e) Dealer confirms that neither the execution and delivery of this Agreement nor the performance of any obligations under this Agreement will conflict with or result in a default under any of the terms or conditions of any agreement or obligation to which Dealer is bound.</p>

            <p>(f) Dealer commits to performing its obligations under this Agreement in a professional manner.</p>

            <p><strong>4. PAYMENT</strong></p>

            <p>4.1 Company agrees to pay Dealer for the data provided within 30 days of receipt of such data. The amount and method of payment will be determined and agreed upon by both Parties prior to the collection of data.</p>

            <p><strong>5. CONFIDENTIALITY</strong></p>

            <p>5.1 During the Term of this Agreement and for a period of five (5) years thereafter, each Party (i) will maintain the other Party’s Confidential Information in confidence, (ii) will limit dissemination to those of its employees who require said Confidential Information in order to perform this Agreement and who have agreed in writing to maintain the confidential nature of all information (including that of third parties) received by them in the course of their engagement on terms no less stringent than those set forth in this Agreement, (iii) will not disclose said Confidential Information to any other person, and (iv) will use said Confidential Information only to the extent necessary to perform this Agreement.  For purposes of this Agreement, “Confidential Information” means any and all information and materials disclosed by one Party (“Discloser”) to the other Party (“Recipient”) (whether in writing or in oral, graphic, electronic or any other form) that is (i) marked “confidential” or otherwise identified in writing as confidential or proprietary at the time of disclosure or (ii) or if not so marked, would be understood by a reasonable receiving party from the context of disclosure or from the information itself to be confidential. The terms and conditions of this Agreement will be deemed the Confidential Information of both Parties.</p>

            <p>5.2 The confidentiality and non-use obligations of this Agreement will not apply to Confidential Information disclosed to the Recipient that:  (i) can be shown by written evidence to be in the Recipient’s possession before receipt of the Confidential Information from the Discloser; (ii) is or becomes publicly available through no fault of the Recipient; (iii) is rightfully received by the Recipient from a third party without breach of a duty of confidentiality to the Discloser; or (iv) is independently developed by the Recipient without use of the information disclosed. Further, nothing in this Agreement will prevent disclosure where required by government or regulatory authority, or court of law, provided that Recipient will promptly notify Discloser in writing prior to making any said disclosure in order to allow Discloser to seek a protective order or other appropriate remedy from the proper authority and in the event that said protective order or other remedy is not obtained, that Recipient will furnish only that portion of the Confidential Information that Recipient is legally required to disclose.</p>

            <p>5.3 Because an award of money damages would be inadequate for any breach of Section 5.1 of this Agreement by a Party and any said breach would cause the other Party irreparable harm, each Party also agrees that in the event of a breach or threatened breach of Section 5.1, the other Party will also be entitled to seek equitable relief, including injunctive relief and specific performance. Said remedies will not be the exclusive remedies for any said breach but will be in addition to all remedies available at law or in equity.</p>

            <p>5.4 Upon termination or expiration of this Agreement, or earlier upon receipt of written request from the Discloser, Recipient agrees to return or destroy all Confidential Information, including materials, received from the Discloser; provided, however, that (i) Recipient may retain in its confidential files one (1) copy of written Confidential Information for record purposes only, and (ii) nothing herein will require Recipient to delete or purge any records in backup or archival systems kept in the normal course of business.</p>

            <p>5.5 Except as required by law or deemed necessary under applicable federal or state securities laws or regulations, neither Party will use the name of the other Party, nor the name of any member of the other Party's staff, in connection with any publicity without the prior written approval of the other Party.</p>

            <p><strong>6. INDEMNIFICATION</strong></p>

            <p>6.1 Dealer shall indemnify, defend, and hold Company, its officers, directors, employees, agents, successors, and assigns, harmless from and against any claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) that arise directly or indirectly from or in connection with: (a) any breach of Dealer's representations, warranties, or obligations set out in this Agreement; (b) any allegation that the data provided by Dealer is not accurate, complete, or current; (c) any infringement or violation of third-party rights, including intellectual property rights, resulting from Company's use of the data provided by Dealer; (d) any unlawful sharing of data by Dealer; or (e) any regulatory fines or penalties resulting from Dealer's improper sharing of data, including but not limited to, violations of data privacy laws or regulations.</p>

            <p>6.2 Indemnification Procedure. Company will have the right to approve any counsel retained to defend against any claim in which Company is named a defendant. Company will have the right to control and participate in the defense of any claim concerning matters that relate to Company, and Dealer will not settle any claim without Company’s reasonable consent. If, in Company’s reasonable judgment, a conflict exists between the interests of Company and Dealer in a claim, Company may retain its own counsel whose reasonable fees will be paid by Dealer.</p>

            <p><strong>7. GOVERNING LAW</strong></p>

            <p>7.1 This Agreement shall be governed by and construed in accordance with the laws of the state of California, without regard to its conflicts of law principles. In the event of any controversy or claim arising out of or in connection with this Agreement, or a breach thereof, the Parties agree to use the following procedure.  Except as otherwise set forth in this Agreement, the Parties shall first attempt to settle the dispute by good faith discussion followed by mediation.  The Parties consent and submit to the exclusive jurisdiction and venue over any Action that may arise out of or in connection with this Agreement in the courts located in San Diego, California.  In the event of any litigation arising out of this Agreement, the prevailing Party shall be entitled to recover its reasonable attorney's fees and costs.</p>

            <p><strong>8. MISCELLANEOUS</strong></p>

            <p>8.1 This Agreement constitutes the entire agreement between Dealer and Company with respect to the subject matter hereof and supersedes all prior discussions, negotiations, and agreement, whether oral or written.</p>

            <p>8.2 Waiver. The failure of Company to enforce any provision of this Agreement shall not be deemed as a waiver of such provision or the right to enforce it at a later time. Any waiver of a provision shall only be effective if it is in writing and signed by an authorized representative of Company.</p>

            <p>8.3 Assignment. Dealer shall not assign, transfer, or delegate any of its rights or obligations under this Agreement without the prior written consent of Company. Any attempted assignment, transfer, or delegation without such consent shall be null and void. Company may freely assign, transfer, or delegate this Agreement or any of its rights or obligations hereunder, without the consent of the Dealer.</p>

            <p>8.4 Severability. If any provision of this Agreement is held to be invalid, illegal, or unenforceable under any applicable law, such provision shall be deemed modified to the extent necessary to make it valid, legal, and enforceable, and the remaining provisions of this Agreement shall remain in full force and effect.</p>

            <p>8.5 Survival. Any provisions in this Agreement that by their nature should survive termination or expiration of this Agreement shall survive, including but not limited to the provisions regarding representations, warranties, covenants, indemnification, and governing law.</p>

            <p>8.6 Counterparts. This Agreement may be executed in counterparts, each of which shall be deemed an original, but all of which together shall constitute one and the same instrument. Facsimile or electronically transmitted signatures shall be deemed original signatures for all purposes under this Agreement.</p>

            <p>8.7 Independent Contractors.  The Parties are independent contractors. Neither Party shall have any right to assume, create, or incur any expense, liability, or obligation, express or implied, on behalf of the other Party.  This Agreement is not intended to be nor shall it be construed as a joint venture, association, partnership or other form of a business organization or agency relationship.</p>

            <p>8.8 Trade Compliance.  Dealer will comply with all U.S. Export Control Laws. “U.S. Export Control Laws” means all U.S. export control laws and sanctions regulations in connection with the Supplier’s provision of the Services, including the Export Administration Regulations of the Bureau of Industry and Security (“BIS”), U.S. Department of Commerce; the International Traffic in Arms Regulations administered by the U.S. Department of State; and economic sanctions administered by the Office of Foreign Assets Control (“OFAC”) of the U.S. Department of the Treasury.  Dealer is not identified on OFAC’s List of Specially Designated Nationals, or any other government prohibited parties list, and Dealer will not allow any person or entity identified on those lists to provide services to Company. Dealer does not provide services into or within any U.S. embargoed country or region, or to governments or governmental instrumentalities of any U.S. embargoed country or region, absent a license or other necessary governmental authorization.</p>
          </TextContainer>
          <Box mt={4} display="flex" justifyContent="flex-end">
            <Button onClick={onAccept} variant="contained" color="primary">
              Accept
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

const TextContainer = styled.div`
  font-family: "Poppins", "Arial";
`;

export default TermsOfServiceModal;
