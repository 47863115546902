import api from './index';

export const getUserProfile = async () => {
  const response = await api.get('/user/me');
  return response.data;
};

export const updateUserProfile = async (profileData) => {
  const response = await api.put('/user/update-profile', profileData);
  return response.data;
};

export const updateUserPassword = async (passwordData) => {
  const response = await api.post('/user/update-password', passwordData);
  return response.data;
};

export const userAgreement = async (profileData) => {
  const response = await api.post('/user/agree-tos', profileData);
  return response.data;
};