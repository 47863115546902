import api from './index';

export const getCountries = async () => {
  const response = await api.get('/admin/countries');
  return response.data;
};

export const addCountry = async (countryData) => {
  const response = await api.post('/admin/countries', countryData);
  return response.data;
};

export const modifyCountry = async (countryId, countryData) => {
  const response = await api.put(`/admin/countries/${countryId}`, countryData);
  return response.data;
};

export const getRoles = async () => {
  const response = await api.get('/admin/roles');
  return response.data;
};

export const addRole = async (roleData) => {
  const response = await api.post('/admin/roles', roleData);
  return response.data;
};

export const getUsers = async () => {
  const response = await api.get('/admin/users');
  return response.data;
};

export const getUser = async (userId) => {
  const response = await api.get(`/admin/users/${userId}`);
  return response.data;
};

export const getDealers = async () => {
  const response = await api.get(`/admin/users/dealers`);
  return response.data;
};


export const updateUser = async (userId, params) => {
  const response = await api.put(`/admin/update-user/${userId}`, params);
  return response.data;
};

export const addUser = async (userData) => {
  const response = await api.post('/admin/add-user', userData);
  return response.data;
};

export const deactivateUser = async (userId) => {
  const response = await api.post(`/admin/deactivate-user/${userId}`);
  return response.data;
};

export const activateUser = async (userId) => {
  const response = await api.post(`/admin/activate-user/${userId}`);
  return response.data;
};

export const getAdminPriceLists = async ({ page = 1, per_page = 25, sort_by = 'created_at', sort_dir = 'DESC' }) => {
  const response = await api.get('/admin/price-lists', {
    params: { page, per_page, sort_by, sort_dir },
  });
  return response.data;
};

export const getAdminPriceListsStatuses = async () => {
  const response = await api.get('/admin/price-list-statuses');
  return response.data;
};

export const updateAdminPriceLists = async (fileId, params) => {
  const response = await api.put(`/admin/update-price-list/${fileId}`, params);
  return response.data;
};

export const updateUserPassword = async (userId, passwordData) => {
  const response = await api.put(`/admin/update-user-password/${userId}`, passwordData);
  return response.data;
};

