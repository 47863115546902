import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './views/Login';
import Home from './views/Home';
import UserManagement from './views/UserManagement';
import AddUser from './views/AddUser';
import EditUser from './views/EditUser';  // Import EditUser component
import PrivateRoute from './components/PrivateRoute';
import AdminRoute from './components/AdminRoute';

const App = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
      <Route path="/user-management" element={<AdminRoute><UserManagement /></AdminRoute>} />
      <Route path="/add-user" element={<AdminRoute><AddUser /></AdminRoute>} />
      <Route path="/edit-user/:id" element={<AdminRoute><EditUser /></AdminRoute>} /> 
      {/* Add other routes and wrap them with PrivateRoute if they are protected */}
    </Routes>
  );
};

export default App;
