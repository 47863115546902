import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      ix: 400,
      md: 768,
      lg: 1024,
      xl: 1280,
      xxl: 1920,
    },
  },
  palette: {
    primary: {
      main: '#2196f3',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#707070',
    },
    accent: {
      main: '#c8ad00',
    },
    success: {
      main: '#63ce6b',
    },
    error: {
      main: '#ff8484',
    },
    info: {
      main: '#3bc7e3',
    },
    warning: {
      main: '#fff0a8',
    },
    white: {
      main: '#ffffff',
    },
    background: {
      default: '#2196f3',
    },
    gray: '#b7b7b7',
    items: '#191919',
    blocks: '#2196f3',
    itemsHover: '#ffffff',
    itemsActive: '#959595',
    itemsHoverText: '#3e4748',
    major: '#104378',
    lineBreak: '#ffffff',
    textColor: '#ffffff',
    blockHover: '#ffffff',
    rowHighlight: '#f2f2f2',
    default: '#3e4748',
    filters: '#3e4748',
    cardTextColor: '#3e4748',
    cardLineBreakColor: '#3e4748',
    icons: '#3e4748',
  },
  typography: {
    fontFamily: 'Poppins, Arial',
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#3e4748',
          '&:hover': {
            color:'white',
            backgroundColor: '#7ABCF0',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          boxShadow: 'none',
          color: 'gray',
          border: '1px solid gray',
          '&:hover': {
            color:'white',
            backgroundColor: '#7ABCF0',
            border: '1px solid #2196f3',
          },
        },
      },
    },
  },
});

export default theme;
