import React, { useEffect, useState, useContext, useRef } from 'react';
import styled from "styled-components";
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, TextField, Button, CircularProgress, Box } from '@mui/material';
import { login as loginUser } from '../../api/auth';
import AuthContext from '../../context/AuthContext';
import AlertContext from '../../context/AlertContext';
import logo from '../../images/OB_PrimaryLogo_01-FullColor.png';
import { splitEmail } from '../../utils/email.js';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useContext(AuthContext);
  const { showAlert, clearAlert } = useContext(AlertContext);

  const hasShownAlert = useRef(false); // Ref to track if alert has been shown


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (!hasShownAlert.current && queryParams.get('authorized') === 'false') {
      showAlert('User not authorized, please login to access the app.', 'error');
      hasShownAlert.current = true; // Set the ref to true after showing the alert
    }
  }, [location, showAlert]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    clearAlert();

    try {
      const data = await loginUser(username, password);
      login(data.tokens.access_token, data.tokens.refresh_token, data.user);

      const redirectPath = new URLSearchParams(location.search).get('redirect') || '/';
      navigate(redirectPath);

      const split_email = splitEmail(data.user.email);

      // Set user properties globally
      window.gtag('set', {
        'user_id': data.user.id,
        'username': split_email[0], 
        'domain': split_email[1] 
      });

      // Send a login event
      window.gtag('event', 'login', {
        method: 'standard', // Specify the method, can be 'Google', 'Facebook', etc.
        user_id: data.user.id,
        username: split_email[0],
        domain: split_email[1]
      });

    } catch (error) {
      showAlert('Login failed. Please check your credentials.', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <img src={logo} style={{ height: 300 }} alt="Logo" />

      <Title>Dealers Login</Title>
      <form onSubmit={handleSubmit}>
        <TextField 
          label="Username" 
          variant="outlined" 
          fullWidth 
          margin="normal" 
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          disabled={loading}
        />

        <TextField 
          label="Password" 
          variant="outlined" 
          type="password" 
          fullWidth 
          margin="normal" 
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={loading}
        />

        <Button 
          variant="contained" 
          color="primary" 
          type="submit" 
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Login'}
        </Button>
      </form>

      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
};

const Title = styled.h2`
  font-size: 1.4em;
  border-bottom: 1px solid #2196f3;
  padding: 5px 0;
  margin-top: 20px;
  color: #2196f3;
  font-family: "Poppins", "Arial";
`;

export default Login;
