import React, { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getUserProfile } from '../../api/user';
import AlertContext from '../AlertContext';
import { setTokens, clearTokens, getAccessToken, getRefreshToken } from '../../utils/token';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { showAlert } = useContext(AlertContext);

  useEffect(() => {
    const checkUserProfile = async () => {
      // Skip the profile check if the user is on the login page
      if (location.pathname === '/login') {
        setLoading(false);
        return;
      }

      try {
        const accessToken = getAccessToken();
        const refreshToken = getRefreshToken();
        if (accessToken && refreshToken) {
          const userProfile = await getUserProfile();
          setUser(userProfile);
        } else {
          navigate('/login?authorized=false');
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          showAlert('User not authorized, please login to access the app.', 'error');
          clearTokens();
          navigate('/login?authorized=false');
        } else {
          showAlert('An error occurred. Please try again.', 'error');
        }
      } finally {
        setLoading(false);
      }
    };

    checkUserProfile();
  }, [location.pathname, navigate, showAlert]);

  const login = (accessToken, refreshToken, userData) => {
    setTokens(accessToken, refreshToken);
    setUser(userData);
    setLoading(false); // Set loading to false after login
  };

  const logout = () => {
    clearTokens();
    setUser(null);
    setLoading(false); // Set loading to false after logout
    navigate('/login');
  };

  return (
    <AuthContext.Provider value={{ user, loading, login, logout, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
