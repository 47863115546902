import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { useParams, useNavigate } from 'react-router-dom';
import { Container, TextField, Button, MenuItem, Select, FormControl, InputLabel, CircularProgress, Typography, Box, FormControlLabel, Checkbox } from '@mui/material';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { getCountries, getRoles, getUser, updateUser } from '../../api/admin';

const EditUser = () => {
  const { id } = useParams();
  const [countries, setCountries] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    is_active: false,
    is_superuser: false,
    is_verified: false,
    name: '',
    username: '',
    country_id: '',
    role_id: '',
  });
  const [error, setError] = useState('');
  const [validationError, setValidationError] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [countriesData, rolesData, userData] = await Promise.all([
          getCountries(), 
          getRoles(), 
          getUser(id)
        ]);
        setCountries(countriesData.countries);
        setRoles(rolesData.roles);
        setFormData({
          ...userData,
          country_id: userData.country?.id || '',
          role_id: userData.role?.id || '',
        });
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setError('Failed to fetch data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  const validate = () => {
    let temp = {};
    temp.email = formData.email ? (/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(formData.email) ? "" : "Email is not valid.") : "This field is required.";
    temp.name = formData.name ? "" : "This field is required.";
    temp.username = formData.username ? "" : "This field is required.";
    temp.country_id = formData.country_id ? "" : "This field is required.";
    temp.role_id = formData.role_id ? "" : "This field is required.";
    setValidationError(temp);
    return Object.values(temp).every(x => x === "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    setLoading(true);
    try {
      await updateUser(id, formData);
      navigate('/user-management');
    } catch (error) {
      console.error('Failed to update user:', error);
      if (error.response && error.response.data && error.response.data.errors) {
        setError(error.response.data.errors.join(', '));
      } else {
        setError('Failed to update user. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Header />
      <Container component="main" flex="1" py={2}>
        
        <Title>Edit User</Title>

        {loading && !error ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
            <CircularProgress />
          </Box>
        ) : (
          <form onSubmit={handleSubmit}>
            {error && (
              <Typography color="error" gutterBottom>
                {error}
              </Typography>
            )}
            <TextField
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              error={!!validationError.email}
              helperText={validationError.email}
            />
            <TextField
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              error={!!validationError.name}
              helperText={validationError.name}
            />
            <TextField
              label="Username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              error={!!validationError.username}
              helperText={validationError.username}
            />
            <FormControl fullWidth margin="normal" required error={!!validationError.country_id}>
              <InputLabel>Country</InputLabel>
              <Select
                name="country_id"
                value={formData.country_id || ''}
                onChange={handleChange}
              >
                {countries.map((country) => (
                  <MenuItem key={country.id} value={country.id}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
              {validationError.country_id && <Typography color="error" variant="caption">{validationError.country_id}</Typography>}
            </FormControl>
            <FormControl fullWidth margin="normal" required error={!!validationError.role_id}>
              <InputLabel>Role</InputLabel>
              <Select
                name="role_id"
                value={formData.role_id || ''}
                onChange={handleChange}
              >
                {roles.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
              {validationError.role_id && <Typography color="error" variant="caption">{validationError.role_id}</Typography>}
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.is_active}
                  onChange={handleCheckboxChange}
                  name="is_active"
                />
              }
              label="Active"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.is_superuser}
                  onChange={handleCheckboxChange}
                  name="is_superuser"
                />
              }
              label="Superuser"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.is_verified}
                  onChange={handleCheckboxChange}
                  name="is_verified"
                />
              }
              label="Verified"
            />
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Save Changes
            </Button>
          </form>
        )}
      </Container>
      <Footer />
    </Box>
  );
};

const Title = styled.h2`
  font-size: 1.4em;
  border-bottom: 1px solid #2196f3;
  padding: 5px 0;
  margin-top: 20px;
  color: #2196f3;
  font-family: "Poppins", "Arial";
`;


export default EditUser;
