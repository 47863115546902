import React, { useContext } from 'react';
import { Snackbar, Alert } from '@mui/material';
import AlertContext from '../../context/AlertContext';

const AlertMessage = () => {
  const { alert, hideAlert } = useContext(AlertContext);

  return (
    <Snackbar open={alert.open} autoHideDuration={6000} onClose={hideAlert}>
      <Alert onClose={hideAlert} severity={alert.severity} sx={{ width: '100%' }}>
        {alert.message}
      </Alert>
    </Snackbar>
  );
};

export default AlertMessage;
