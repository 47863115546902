import api from './index';

export const downloadPriceList = async (fileUuid) => {
  const response = await api.get(`/price-list/download/${fileUuid}`, {
    responseType: 'blob',
  });
  return response;
};

export const uploadPriceList = async (file, dealer) => {
  console.log(file)
  let formData = new FormData();
  formData.append('file', file);
  formData.append('dealer_id', dealer);
  console.log('FormData:', formData);
  const response = await api.post('/price-list/upload', formData, {
    headers: {
      'Content-Type': file.type
    }
  });
  return response.data;
};


export const getPriceListStatus = async (fileUuid) => {
  const response = await api.get(`/price-list/status/${fileUuid}`);
  return response.data;
};

export const getPriceLists = async () => {
  const response = await api.get('/price-list');
  return response.data;
};
