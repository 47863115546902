import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AuthContext from '../../context/AuthContext';
import logo from '../../images/OB_PrimaryLogo_03-White.png';
import { getUserProfile } from '../../api/user';

const Header = () => {
  const { user, logout } = useContext(AuthContext);
  const [username, setUsername] = useState(user ? user.username : '');
  const [isSuperUser, setIsSuperUser] = useState(user ? user.is_superuser : false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [accountAnchorEl, setAccountAnchorEl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (!username) {
        try {
          const profile = await getUserProfile();
          setUsername(profile.username);
          setIsSuperUser(profile.is_superuser);
        } catch (error) {
          console.error('Failed to fetch user profile:', error);
        }
      }
    };

    fetchUserProfile();
  }, [username]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAccountMenu = (event) => {
    setAccountAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAccountAnchorEl(null);
  };

  const handleAdminClick = () => {
    navigate('/admin');
    handleClose();
  };

  const handleHomeClick = () => {
    navigate('/');
    handleClose();
  };

  const handleLogout = () => {
    logout();
    handleClose();
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: '#2196f3', boxShadow: 'none' }}>
      <Toolbar>
        {isSuperUser ? (
          <>
            <IconButton sx={{ color: '#fff' }} edge="start" color="inherit" aria-label="menu" onClick={handleMenu}>
              <MenuIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem onClick={handleHomeClick}>Home</MenuItem>
              <MenuItem onClick={() => navigate('/user-management')}>User Management</MenuItem>
            </Menu>
            <img src={logo} alt="Logo" style={{ height: 40, cursor: 'pointer' }} onClick={handleHomeClick} />
          </>
        ) : (
          <Box display="flex" alignItems="center">
            <img src={logo} alt="Logo" style={{ height: 40, cursor: 'pointer' }} onClick={handleHomeClick} />
          </Box>
        )}
        <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>

        </Typography>
        <Box display="flex" alignItems="center">
          <Typography variant="body1" sx={{ marginRight: 2 }}>
            {username}
          </Typography>
          <IconButton sx={{ color: '#fff' }} edge="end" color="inherit" onClick={handleAccountMenu}>
            <AccountCircle />
          </IconButton>
          <Menu
            anchorEl={accountAnchorEl}
            keepMounted
            open={Boolean(accountAnchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
