import React, { useEffect, useState, useContext } from 'react';
import styled from "styled-components";
import {
  Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Typography, Box, IconButton, CircularProgress, Button, LinearProgress, Modal, Radio, RadioGroup, FormControlLabel, TextField, Pagination, TableSortLabel, Select, Menu, MenuItem, Alert, Snackbar
} from '@mui/material';
import { Download, CheckCircle, Cancel, Clear, Edit, ExpandMore, ExpandLess, Upload, MoreVert, Refresh } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import AuthContext from '../../context/AuthContext';
import { getAdminPriceLists, getAdminPriceListsStatuses, updateAdminPriceLists, getDealers } from '../../api/admin';
import { getPriceLists, downloadPriceList, uploadPriceList } from '../../api/priceList';
import TermsOfServiceModal from '../../modals/TermsOfService';
import { updateUserProfile, userAgreement } from '../../api/user';

const Home = () => {
  const { user, setUser } = useContext(AuthContext);
  const [priceLists, setPriceLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [downloading, setDownloading] = useState(null);
  const [uploads, setUploads] = useState([]);
  const [isTosModalOpen, setIsTosModalOpen] = useState(!user.agree_tos);
  const [isDragActive, setIsDragActive] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [sortBy, setSortBy] = useState('created_at');
  const [sortDir, setSortDir] = useState('DESC');
  const [statuses, setStatuses] = useState([]);
  const [editingFile, setEditingFile] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalStatus, setModalStatus] = useState('');
  const [modalNotes, setModalNotes] = useState('');
  const [expandedRows, setExpandedRows] = useState({});
  const [dealers, setDealers] = useState([]);
  const [selectedDealer, setSelectedDealer] = useState('');
  const [uploadError, setUploadError] = useState('');

  // State for handling dropdown menu
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPriceList, setSelectedPriceList] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    fetchData();
    if (user.is_superuser) {
      fetchDealers();
    }
  }, [user, page, perPage, sortBy, sortDir]);

  const fetchData = async () => {
    setLoading(true);
    try {
      let data;
      if (user.is_superuser) {
        data = await getAdminPriceLists({ page, per_page: perPage, sort_by: sortBy, sort_dir: sortDir });
        const statusData = await getAdminPriceListsStatuses();
        setStatuses(statusData.statuses);
      } else {
        data = await getPriceLists({ page, per_page: perPage, sort_by: sortBy, sort_dir: sortDir });
      }
      setPriceLists(data.price_lists);
      setPages(data.pages); // Update the pages state with the total number of pages from the API
    } catch (error) {
      console.error('Failed to fetch price lists:', error);
      setError('Failed to fetch price lists.');
    } finally {
      setLoading(false);
    }
  };

  const fetchDealers = async () => {
    try {
      const dealerData = await getDealers(); // Adjust your API endpoint accordingly
      setDealers(dealerData.users);
    } catch (error) {
      console.error('Failed to fetch dealers:', error);
    }
  };

  const handleDownload = async (fileUuid, fileName) => {
    if (downloading) {
      return;
    }
    handleMenuClose();
    setDownloading(fileUuid);
    setSnackbarMessage('Download is processing and will start shortly...');
    setSnackbarOpen(true);
    try {
      const response = await downloadPriceList(fileUuid);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Failed to download file:', error);
      setError('Failed to download file. Please try again.');
    } finally {
      setDownloading(null);
    }

  };

  const handleAcceptTos = async () => {
    try {
      const now = new Date();
      const isoString = now.toISOString().split('.')[0]; // Removing milliseconds for simplicity
      await userAgreement({ ...user, agree_tos: true, agree_tos_at: isoString });
      setUser({ ...user, agree_tos: true, agree_tos_at: isoString });
      setIsTosModalOpen(false);
    } catch (error) {
      console.error('Failed to accept terms of service:', error);
      setError('Failed to accept terms of service. Please try again.');
    }
  };

  const onDrop = (acceptedFiles) => {
    const newUploads = acceptedFiles.map((file) => ({
      file,
      progress: 0,
      status: 'staged',
      error: null,
    }));
    setUploads((prevUploads) => [...prevUploads, ...newUploads]);
  };

  const uploadFiles = async () => {
    if (user.is_superuser && !selectedDealer) {
      setUploadError('Please select a dealer.');
      return;
    }
    setUploadError('');

    for (const upload of uploads) {
      if (upload.status === 'completed') {
        continue; // Skip files that have already been uploaded successfully
      }

      setUploads((prevUploads) =>
        prevUploads.map((u) =>
          u.file === upload.file ? { ...u, status: 'uploading' } : u
        )
      );
      await uploadFile(upload);
    }
  };

  const uploadFile = async (file) => {
    try {
      await uploadPriceList(file.file, selectedDealer || user.id);
      setUploads((prevUploads) =>
        prevUploads.map((upload) =>
          upload.file === file.file ? { ...upload, status: 'completed', progress: 100 } : upload
        )
      );
      fetchData();
    } catch (error) {
      setUploads((prevUploads) =>
        prevUploads.map((upload) =>
          upload.file === file.file ? { ...upload, status: 'failed', error: error.message } : upload
        )
      );
    }
  };

  const clearUploads = () => {
    setUploadError('');
    setUploads([]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDragEnter: () => setIsDragActive(true),
    onDragLeave: () => setIsDragActive(false),
    onDropAccepted: () => setIsDragActive(false),
  });

  const handleEdit = (file) => {
    handleMenuClose();
    setEditingFile(file);
    setModalStatus(file.status);
    setModalNotes(file.notes || '');
    setModalOpen(true);
  };

  const handleUpdateFile = async () => {
    try {
      await updateAdminPriceLists(editingFile.uuid, { status: modalStatus, notes: modalNotes });
      fetchData();
      setModalOpen(false);
    } catch (error) {
      console.error('Failed to update file:', error);
    }
  };

  const handleSort = (property) => {
    const isAsc = sortBy === property && sortDir === 'ASC';
    setSortBy(property);
    setSortDir(isAsc ? 'DESC' : 'ASC');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const toggleRow = (id) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [id]: !prevExpandedRows[id],
    }));
  };

  const handleMenuClick = (event, priceList) => {
    setAnchorEl(event.currentTarget);
    setSelectedPriceList(priceList);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedPriceList(null);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Header />
      <Container component="main" flex="1" py={2}>

        <Title>Dealer Price List</Title>

        {user.role.name !== 'user' && (
          <Box
            {...getRootProps()}
            sx={{
              border: '2px dashed gray',
              borderRadius: 2,
              p: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              bgcolor: isDragActive ? 'rgba(255, 255, 255, 0.5)' : 'transparent',
              height: isDragActive ? '200px' : 'auto',
              cursor: 'pointer',
              marginBottom: '10px',
              '&:hover': {
                bgcolor: '#7ABCF0',
                borderColor: '#2196f3',
                color: '#fff',
              },
            }}
          >
            <input {...getInputProps()} style={{ display: 'none' }} />

            <Typography variant="body1" mt={2}>
              Drag and drop files to stage
            </Typography>
          </Box>
        )}

        {uploads.length > 0 && (
          <React.Fragment>
            {user.is_superuser && (
              <Box mb={2}>
                <Select
                  value={selectedDealer}
                  onChange={(e) => setSelectedDealer(e.target.value)}
                  displayEmpty
                  fullWidth
                  sx={{ textTransform: 'capitalize' }} // Capitalize dealer names
                >
                  <MenuItem value="" disabled>
                    Select Dealer
                  </MenuItem>
                  {dealers.map((dealer) => (
                    <MenuItem key={dealer.id} value={dealer.id} sx={{ textTransform: 'capitalize' }}>
                      {dealer.name}
                    </MenuItem>
                  ))}
                </Select>
                {uploadError && (
                  <Alert severity="error" sx={{ mt: 2 }}>
                    {uploadError}
                  </Alert>
                )}
              </Box>
            )}

            <Box mt={4}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <LeftUploads>
                  <Typography variant="h6" sx={{ marginRight: '5px' }}>Staged Uploads </Typography>
                  <UploadInfo>
                    ({uploads.filter((upload) => upload.status === 'uploading').length} uploading, {uploads.filter((upload) => upload.status === 'failed').length} failed)
                  </UploadInfo>
                </LeftUploads>

                <RightUploads>
                  <Button onClick={clearUploads} startIcon={<Clear />} sx={{ ml: 1, mr: 1 }}>
                    Clear
                  </Button>
                  <Button onClick={uploadFiles} startIcon={<Upload />} sx={{ ml: 1, mr: 1 }}>
                    Upload All
                  </Button>
                </RightUploads>
              </Box>
              {uploads.map((upload, index) => (
                <Box
                  key={index}
                  mt={2}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    bgcolor: index % 2 === 0 ? 'grey.100' : 'grey.200',
                    p: 1,
                    borderRadius: 1,
                  }}
                >
                  <Typography variant="body2" sx={{ flex: 1 }}>{upload.file.name}</Typography>
                  <LinearProgress
                    variant="determinate"
                    value={upload.progress}
                    color={upload.status === 'failed' ? 'error' : 'primary'}
                    sx={{ flex: 1, mr: 2 }}
                  />
                  {upload.status === 'completed' ? (
                    <CheckCircle color="success" />
                  ) : upload.status === 'failed' ? (
                    <Cancel color="error" />
                  ) : upload.status === 'uploading' ? (
                    <CircularProgress size={24} />
                  ) : null}
                </Box>
              ))}
            </Box>
          </React.Fragment>
        )}

        <Box mt={uploads.length > 0 ? 4 : 0}>
          {loading ? (
            <Typography>Loading List...</Typography>
          ) : (
            <>
              {priceLists.length === 0 ? (
                <Box textAlign="center" mt={4}>
                  <Typography>No price lists available.</Typography>
                </Box>
              ) : (
                <>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ width: 40 }}>
                            <IconButton onClick={fetchData}>
                              <Refresh />
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={sortBy === 'file_name'}
                              direction={sortBy === 'file_name' ? sortDir.toLowerCase() : 'asc'}
                              onClick={() => handleSort('file_name')}
                            >
                              File Name
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={sortBy === 'status'}
                              direction={sortBy === 'status' ? sortDir.toLowerCase() : 'asc'}
                              onClick={() => handleSort('status')}
                            >
                              Status
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={sortBy === 'country_id'}
                              direction={sortBy === 'country_id' ? sortDir.toLowerCase() : 'asc'}
                              onClick={() => handleSort('country_id')}
                            >
                              Country
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={sortBy === 'dealer_id'}
                              direction={sortBy === 'dealer_id' ? sortDir.toLowerCase() : 'asc'}
                              onClick={() => handleSort('dealer_id')}
                            >
                              Dealer
                            </TableSortLabel>
                          </TableCell>
                          <TableCell sx={{ width: 105 }}>
                            <TableSortLabel
                              active={sortBy === 'created_at'}
                              direction={sortBy === 'created_at' ? sortDir.toLowerCase() : 'asc'}
                              onClick={() => handleSort('created_at')}
                            >
                              Created At
                            </TableSortLabel>
                          </TableCell>
                          <TableCell sx={{ width: 110 }}>
                            <TableSortLabel
                              active={sortBy === 'modified_at'}
                              direction={sortBy === 'modified_at' ? sortDir.toLowerCase() : 'asc'}
                              onClick={() => handleSort('modified_at')}
                            >
                              Modified At
                            </TableSortLabel>
                          </TableCell>
                          <TableCell sx={{ width: 80 }}>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {priceLists.map((priceList, index) => (
                          <React.Fragment key={priceList.id}>
                            <TableRow
                              sx={{
                                bgcolor:
                                  priceList.status === 'APPROVED'
                                    ? 'lightgreen'
                                    : priceList.status === 'REJECTED'
                                    ? 'lightcoral'
                                    : index % 2 === 0
                                    ? 'grey.100'
                                    : 'grey.200',
                              }}
                            >
                              <StyledTableCell status={priceList.status} sx={{ width: 40 }}>
                                <IconButton onClick={() => toggleRow(priceList.id)}>
                                  {expandedRows[priceList.id] ? <ExpandLess /> : <ExpandMore />}
                                </IconButton>
                              </StyledTableCell>
                              <StyledTableCell status={priceList.status}>
                                <Box display="flex" alignItems="center">
                                  <Box
                                    sx={{
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      maxWidth: 200,
                                    }}
                                    title={priceList.file_name}
                                    fontWeight="bold"
                                  >
                                    {priceList.file_name}
                                  </Box>
                                </Box>
                              </StyledTableCell>
                              <StyledTableCell status={priceList.status}>{priceList.status}</StyledTableCell>
                              <StyledTableCell status={priceList.status}>{priceList?.country?.name || 'N/A'}</StyledTableCell>
                              <StyledTableCell status={priceList.status}>{priceList?.dealer?.name || 'N/A'}</StyledTableCell>
                              <StyledTableCell status={priceList.status} sx={{ width: 105 }}>
                                <Box fontWeight="bold">{new Date(priceList.created_at).toLocaleDateString()}</Box>
                                <Box>{new Date(priceList.created_at).toLocaleTimeString()}</Box>
                              </StyledTableCell>
                              <StyledTableCell status={priceList.status} sx={{ width: 110 }}>
                                <Box fontWeight="bold">{new Date(priceList.modified_at).toLocaleDateString()}</Box>
                                <Box>{new Date(priceList.modified_at).toLocaleTimeString()}</Box>
                              </StyledTableCell>
                              <StyledTableCell status={priceList.status} sx={{ width: 80 }}>
                                {user.is_superuser ? (
                                  <>
                                    <IconButton onClick={(event) => handleMenuClick(event, priceList)}>
                                      <MoreVert />
                                    </IconButton>
                                    <Menu
                                      anchorEl={anchorEl}
                                      open={Boolean(anchorEl) && selectedPriceList?.id === priceList.id}
                                      onClose={handleMenuClose}
                                    >
                                      <MenuItem onClick={() => handleDownload(priceList.uuid, priceList.file_name)}>Download</MenuItem>
                                      <MenuItem onClick={() => handleEdit(priceList)}>Edit</MenuItem>
                                    </Menu>
                                  </>
                                ) : (
                                  <IconButton onClick={() => handleDownload(priceList.uuid, priceList.file_name)} disabled={downloading === priceList.uuid}>
                                    {downloading === priceList.uuid ? <CircularProgress size={24} /> : <Download />}
                                  </IconButton>
                                )}
                              </StyledTableCell>
                            </TableRow>
                            {expandedRows[priceList.id] && (
                              <TableRow>
                                <TableCell colSpan={8} sx={{ bgcolor: index % 2 === 0 ? 'grey.100' : 'grey.200' }}>
                                  <Typography variant="body2" p={2}>
                                    {priceList.notes || 'No notes available.'}
                                  </Typography>
                                  <ExtraData>
                                    <FileName>
                                      <span>File:</span> {priceList.file_name}
                                    </FileName>
                                    <UUID p={1}>{priceList.uuid}</UUID>
                                  </ExtraData>
                                </TableCell>
                              </TableRow>
                            )}
                          </React.Fragment>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Pagination
                    count={pages}
                    page={page}
                    onChange={handleChangePage}
                    sx={{ mt: 2 }}
                  />
                </>
              )}
              {error && (
                <Box textAlign="center" mt={4}>
                  <Typography color="error">{error}</Typography>
                </Box>
              )}
            </>
          )}
        </Box>
      </Container>
      <Footer />

      <TermsOfServiceModal open={isTosModalOpen} onClose={() => {}} onAccept={handleAcceptTos} />

      {/* Modal for editing file status and notes */}
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Box
            bgcolor="background.paper"
            p={4}
            borderRadius={2}
            boxShadow={24}
            width="400px"
          >
            <Typography variant="h6" mb={2}>Edit File</Typography>
            <RadioGroup
              value={modalStatus}
              onChange={(e) => setModalStatus(e.target.value)}
              sx={{ mb: 2 }}
            >
              {statuses.map((status) => (
                <FormControlLabel
                  key={status}
                  value={status}
                  control={<Radio />}
                  label={status}
                />
              ))}
            </RadioGroup>
            <TextField
              label="Notes"
              multiline
              rows={4}
              value={modalNotes}
              onChange={(e) => setModalNotes(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            />
            <Box display="flex" justifyContent="flex-end">
              <Button onClick={handleCloseModal} sx={{ mr: 2 }}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleUpdateFile}>
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </Box>
  );
};

const Title = styled.h2`
  font-size: 1.4em;
  border-bottom: 1px solid #2196f3;
  padding:5px 0;
  margin-top:20px;
  color:#2196f3;
  font-family: "Poppins", "Arial";
`;

const StyledTableCell = styled(TableCell)`
  border-bottom: 2px solid ${({ status }) =>
    status === 'APPROVED' ? 'green' : status === 'REJECTED' ? 'red' : 'inherit'};
`;

const LeftUploads = styled.div`
  display:flex;
`;

const RightUploads = styled.div`
  display:flex;
`;

const UploadInfo = styled.span`
  line-height:2.7;
  font-size:12px;
`;

const ExtraData = styled.div`
  display:flex;
`;

const UUID = styled.div`
  margin-left:auto;
  font-size:10px;
`;

const FileName = styled.div`
  font-size:12px;

  span {
    font-weight:bold;
  }

`;

export default Home;
