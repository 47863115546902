import api from './index';

export const oauthLogin = async (data) => {
  const response = await api.post('/auth/oauth-login', data);
  return response.data;
};

export const login = async (username, password) => {
  const response = await api.post('/auth/login', { username, password });
  return response.data;
};
