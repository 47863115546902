// src/utils/emailUtils.js

export function splitEmail(email) {
  const parts = email.split('@'); // Split the email by the '@' symbol
  if (parts.length === 2) {
    const username = parts[0];
    const domain = parts[1];
    return { username, domain };
  } else {
    throw new Error('Invalid email format');
  }
}