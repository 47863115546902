import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import App from './App';
import theme from './theme';
import { AuthProvider } from './context/AuthContext';
import { AlertProvider } from './context/AlertContext';
import AlertMessage from './components/AlertMessage';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
      <StyledThemeProvider theme={theme}>
        <Router>
          <AlertProvider>
            <AuthProvider>
              <App />
              <AlertMessage />
            </AuthProvider>
          </AlertProvider>
        </Router>
      </StyledThemeProvider>
    </MuiThemeProvider>
  </React.StrictMode>
);
