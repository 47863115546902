import React, { useState } from 'react';
import { Container, Box, Typography, Link } from '@mui/material';
import TermsOfServiceModal from '../../modals/TermsOfService';

const Footer = () => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleAcceptModal = () => {
    // Handle accept logic here
    setOpenModal(false);
  };

  return (
    <Box component="footer" py={3} bgcolor="background.paper">
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <Typography variant="body2" color="textSecondary" align="center">
            © {new Date().getFullYear()} Openbrand
          </Typography>
          <Box mt={2} display="flex" justifyContent="center" alignItems="center">
            <Link
              href="https://deepad.atlassian.net/servicedesk/customer/portal/9"
              variant="body2"
              color="inherit"
              underline="hover"
              mx={2}
            >
              Contact Support
            </Link>
            <Link
              component="button"
              variant="body2"
              color="inherit"
              underline="hover"
              mx={2}
              onClick={handleOpenModal} // Open the modal on click
            >
              Terms of Service
            </Link>
          </Box>
        </Box>
      </Container>

      {/* Render the modal */}
      <TermsOfServiceModal 
        open={openModal} 
        onClose={handleCloseModal} 
        onAccept={handleAcceptModal} 
      />
    </Box>
  );
};

export default Footer;
