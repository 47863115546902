import React, { useEffect, useState, useContext } from 'react';
import styled from "styled-components";
import { 
  Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, Button, CircularProgress, IconButton, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Snackbar 
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Edit as EditIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import AuthContext from '../../context/AuthContext';
import { getUsers, deactivateUser, activateUser, updateUserPassword } from '../../api/admin';

const UserManagement = () => {
  const { user } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await getUsers();
      setUsers(data.users);
    } catch (error) {
      console.error('Failed to fetch users:', error);
      setError('Failed to fetch users. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleAddUser = () => {
    navigate('/add-user');
  };

  const handleEditUser = (id) => {
    navigate(`/edit-user/${id}`);
  };

  const handleMenuClick = (event, user) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(user);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedUser(null);
  };

  const handleDeactivateActivateUser = async () => {
    setLoading(true);
    try {
      if (selectedUser.is_active) {
        await deactivateUser(selectedUser.id);
        setSnackbarMessage('User deactivated successfully.');
      } else {
        await activateUser(selectedUser.id);
        setSnackbarMessage('User activated successfully.');
      }
    } catch (error) {
      console.error('Failed to update user status:', error);
      setSnackbarMessage('Failed to update user status.');
    } finally {
      handleMenuClose();
      setSnackbarOpen(true);
      fetchData();
      setLoading(false);
    }
  };

  const handleResetPassword = async () => {
    setLoading(true);
    try {
      await updateUserPassword(selectedUser.id, { new_password: newPassword });
      setSnackbarMessage('User password reset successfully.');
    } catch (error) {
      console.error('Failed to reset password:', error);
      setSnackbarMessage('Failed to reset password.');
    } finally {
      setModalOpen(false);
      setSnackbarOpen(true);
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  if (!user.is_superuser) {
    return <Typography variant="h4">Unauthorized Access</Typography>;
  }

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Header />
      <Container component="main" flex="1" py={2}>
        <Title>User Management</Title>

        <Button variant="contained" color="primary" onClick={handleAddUser}>
          Add User
        </Button>

        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Country</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Superuser</TableCell>
                  <TableCell>Role</TableCell> {/* New column for Role */}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>{user.id}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.country.code}</TableCell>
                    <TableCell>{user.is_active ? 'Yes' : 'No'}</TableCell>
                    <TableCell>{user.is_superuser ? 'Yes' : 'No'}</TableCell>
                    <TableCell>{user.role?.name || 'N/A'}</TableCell> {/* Display role name */}
                    <TableCell>
                      <IconButton color="primary" onClick={(event) => handleMenuClick(event, user)}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && selectedUser?.id === user.id} // Open only for the selected user
                        onClose={handleMenuClose}
                      >
                        <MenuItem onClick={() => handleEditUser(user.id)}>Edit User</MenuItem>
                        <MenuItem onClick={handleDeactivateActivateUser}>
                          {user.is_active ? 'Deactivate User' : 'Activate User'}
                        </MenuItem>
                        <MenuItem onClick={() => setModalOpen(true)}>Reset Password</MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
          <DialogTitle>Reset User Password</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter a new password for the user.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="New Password"
              type="password"
              fullWidth
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModalOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleResetPassword} color="primary" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Reset Password'}
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
        />
      </Container>
      <Footer />
    </Box>
  );
};

const Title = styled.h2`
  font-size: 1.4em;
  border-bottom: 1px solid #2196f3;
  padding: 5px 0;
  margin-top: 20px;
  color: #2196f3;
  font-family: "Poppins", "Arial";
`;

export default UserManagement;
