import React, { createContext, useState } from 'react';

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const initialAlertState = { message: '', severity: 'info', open: false };
  const [alert, setAlert] = useState(initialAlertState);

  const showAlert = (message, severity = 'info') => {
    setAlert({ message, severity, open: true });
  };

  const hideAlert = () => {
    setAlert({ ...alert, open: false });
  };

  const clearAlert = () => {
    setAlert(initialAlertState); // Reset to initial state instead of null
  };

  return (
    <AlertContext.Provider value={{ alert, showAlert, hideAlert, clearAlert }}>
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContext;
