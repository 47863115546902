import axios from 'axios';
import { getAccessToken, clearTokens } from '../utils/token';
import { useNavigate } from 'react-router-dom';

const api = axios.create({
  baseURL: process.env.APP_API_URL || 'http://localhost:8000/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      clearTokens();
      const navigate = useNavigate();
      navigate('/login?authorized=false');
    }
    return Promise.reject(error);
  }
);

export default api;
